import Serializer from '@/Misc/Serializer';
import FileInfo from '@/models/FileInfo';
import DTLocation from './DTLocation';
import moment, { Moment } from 'moment';
import Backbone from '@/core/Backbone';

export default class Well implements IWell {
	Id: string = '';
	OrganizationId: string = '';
	CustomerId: string = '';
	Name?: string | undefined;
	Material?: string | undefined;
	CoverIsVisible?: string | undefined = 'Other';
	WellDepth?: number | undefined;
	//WellInnerDiamater?: number | undefined;
	WellType: string | undefined = '';
	InstallationDate: moment.Moment = moment(-8640000000000);
	InstalledByName?: string | undefined;
	ServiceInterval: number = 12;
	SignIsInstalled?: string | undefined = 'Other';
	QRCode?: string | undefined;
	Note?: string | undefined;
	LatestServiceVisit: moment.Moment = moment(-8640000000000);
	State: WellState = WellState.Active;
	CreatedDate: Date = new Date();
	ModifiedMode: Date = new Date();
	Images: FileInfo[] = [];
	InstallationQty?: number = 1;
	ServiceStatus: EWellServiceStatus = EWellServiceStatus.Done;
	public Location: DTLocation = new DTLocation();
	public Barriers: DTBarrier[] = [];

	NextPlannedServiceId: string = '';
	NextPlannedServiceDate?: moment.Moment;
	NextPlannedServiceDateEnd?: moment.Moment; // If no service planned undefined
	NextPlannedServiceUserId: string = '';
	NextPlannedServiceUserName: string = '';

	NextPlannedOrEstimatedServiceDate: moment.Moment = moment();

	public get IsPlanned() {
		return !(this.NextPlannedServiceId == undefined || this.NextPlannedServiceId == '');
	}

	get EstimatedServiceDate(): Moment {
		return moment(this.LatestServiceVisit).add(this.ServiceInterval, 'months');
	}

	get WellDepthMM(): number {
		return this.WellDepth ? Math.round(this.WellDepth * 1000) : 0;
	}

	set WellDepthMM(value: number) {
		this.WellDepth = Math.round(value) / 1000;
	}

	get hasServiceAgreement(): boolean {
		return Backbone.State.Addresses.find((a) => a.Id == this.CustomerId)?.HasServiceAgreement ?? false;
	}

	public Deserialize() {
		Serializer.StringToDate(['CreatedDate', 'ModifiedMode'], this);
		Serializer.StringToMoment(['NextPlannedServiceDate', 'NextPlannedServiceDateEnd'], this);
		Serializer.StringToMomentWithFallback('InstallationDate', this, moment(-8640000000000));
		Serializer.StringToMomentWithFallback('LatestServiceVisit', this, moment(this.InstallationDate));

		Serializer.StringToLocation('Location', this);
		this.UpdateData();
	}

	public UpdateData() {
		//Hack The dropdown selection used to say BETON and not Beton
		if (this.Material == 'BETON') {
			this.Material = 'Beton';
		}

		if (this.ServiceInterval < 1) {
			this.ServiceInterval = 12;
		}

		if (this.InstallationDate.isSame(moment(-8640000000000))) {
			this.InstallationDate = moment(this.CreatedDate);
		}

		if (this.LatestServiceVisit.isSame(moment(-8640000000000))) {
			this.LatestServiceVisit = moment(this.InstallationDate);
		}

		let planned = this.IsPlanned;

		if (planned && moment().isAfter(this.NextPlannedServiceDate, 'days')) {
			planned = false;
			this.NextPlannedServiceId = '';
			this.NextPlannedServiceDate = undefined;
			this.NextPlannedServiceDateEnd = undefined;
			this.NextPlannedServiceUserId = '';
			this.NextPlannedServiceUserName = '';
		}

		if (planned) {
			this.NextPlannedOrEstimatedServiceDate = this.NextPlannedServiceDate!;
		} else {
			this.NextPlannedOrEstimatedServiceDate = this.EstimatedServiceDate;
		}

		const now = moment();
		const daysToEstService = this.EstimatedServiceDate.diff(now, 'days');

		if (planned && now.isAfter(this.NextPlannedOrEstimatedServiceDate, 'days')) {
			this.ServiceStatus = EWellServiceStatus.OverduePlanned;
		} else if (!planned && now.isAfter(this.EstimatedServiceDate, 'days')) {
			this.ServiceStatus = EWellServiceStatus.Overdue;
		} else if (daysToEstService < Backbone.State.Organisation.ServiceSoonPeriodDays) {
			if (planned) {
				this.ServiceStatus = EWellServiceStatus.SoonPlanned;
			} else {
				this.ServiceStatus = EWellServiceStatus.Soon;
			}
		} else if (planned) {
			this.ServiceStatus = EWellServiceStatus.DonePlanned;
		} else {
			this.ServiceStatus = EWellServiceStatus.Done;
		}

		if (!this.hasServiceAgreement) {
			if (this.ServiceStatus == EWellServiceStatus.Done || this.ServiceStatus == EWellServiceStatus.DonePlanned) {
				this.ServiceStatus = EWellServiceStatus.DoneNoAgreement;
			}

			if (this.ServiceStatus == EWellServiceStatus.Soon || this.ServiceStatus == EWellServiceStatus.SoonPlanned) {
				this.ServiceStatus = EWellServiceStatus.SoonNoAgreement;
			}

			if (this.ServiceStatus == EWellServiceStatus.Overdue || this.ServiceStatus == EWellServiceStatus.OverduePlanned) {
				this.ServiceStatus = EWellServiceStatus.OverdueNoAgreement;
			}
		}

		// const daysToEstService = this.EstimatedServiceDate.diff(moment(), 'days');

		// if (planned) {
		// 	this.NextPlannedOrEstimatedServiceDate = moment(this.NextPlannedServiceDate);
		// } else {
		// 	this.NextPlannedOrEstimatedServiceDate = this.EstimatedServiceDate;
		// }

		// if (moment().isAfter(this.NextPlannedOrEstimatedServiceDate, 'days')) {
		// 	if (planned) {
		// 		this.ServiceStatus = EWellServiceStatus.OverduePlanned;
		// 	} else {
		// 		this.ServiceStatus = EWellServiceStatus.Overdue;
		// 	}
		// } else if (daysToEstService < Backbone.State.Organisation.ServiceSoonPeriodDays) {
		// 	if (daysToEstService > 0) {
		// 		if (planned) {
		// 			this.ServiceStatus = EWellServiceStatus.SoonPlanned;
		// 		} else {
		// 			this.ServiceStatus = EWellServiceStatus.Soon;
		// 		}
		// 	} else {
		// 		if (planned) {
		// 			this.ServiceStatus = EWellServiceStatus.OverduePlanned;
		// 		} else {
		// 			this.ServiceStatus = EWellServiceStatus.Overdue;
		// 		}
		// 	}
		// } else if (planned) {
		// 	this.ServiceStatus = EWellServiceStatus.DonePlanned;
		// } else {
		// 	this.ServiceStatus = EWellServiceStatus.Done;
		// }
	}

	public GetStatusText(): string {
		let result = '';
		switch (this.ServiceStatus) {
			case EWellServiceStatus.Done:
			case EWellServiceStatus.Soon:
				result = 'Næste service inden ' + this.NextPlannedOrEstimatedServiceDate.format('L');
				break;
			case EWellServiceStatus.DonePlanned:
			case EWellServiceStatus.SoonPlanned:
				result = 'Næste planlagt service den ' + this.NextPlannedOrEstimatedServiceDate.format('L');
				break;
			case EWellServiceStatus.Overdue:
				result = 'Service overskredet med ' + moment().diff(this.NextPlannedOrEstimatedServiceDate, 'days') + ' dage';
				break;
			case EWellServiceStatus.OverduePlanned:
				result = 'Planlagt service overskredet med ' + moment().diff(this.NextPlannedOrEstimatedServiceDate, 'days') + ' dage';
				break;
		}

		result += '<br />Seneste servicedato ' + this.LatestServiceVisit.format('L');

		return result;
	}

	public GetDetailText() {
		return `${this.InstallationQty} rottespærrer, serviceintv. ${this.ServiceInterval} mdr.`;
	}
}

export interface IWell {
	Id: string; // Cannot be null
	OrganizationId: string; // Cannot be null
	CustomerId: string; // Cannot be null
	Name?: string;
	Material?: string;
	CoverIsVisible?: string;
	WellDepth?: number;
	// WellInnerDiamater?:     number;
	WellType?: string;
	InstallationDate: moment.Moment;
	InstalledByName?: string;
	ServiceInterval?: number;
	SignIsInstalled?: string;
	QRCode?: string;
	Note?: string;
	LatestServiceVisit: moment.Moment; // Cannot be null
	State: WellState; // Changed to enum and cannot be null
	CreatedDate: Date; // Cannot be null
	ModifiedMode: Date; // Cannot be null
	Images: FileInfo[]; // Cannot be null
	InstallationQty?: number;
	ServiceStatus: EWellServiceStatus; // Not awailable on ser - set on client
	NextPlannedServiceId: string; // If no service planned = ""
	NextPlannedServiceUserId: string;
	NextPlannedServiceUserName: string;
	NextPlannedServiceDate?: moment.Moment; // If no service planned undefined
	NextPlannedServiceDateEnd?: moment.Moment; // If no service planned undefined
	Location: DTLocation;
}

export enum EWellServiceStatus {
	Done = 0,
	DonePlanned = 1,
	DoneNoAgreement = 2,
	Soon = 10,
	SoonPlanned = 11,
	SoonNoAgreement = 12,
	Overdue = 20,
	OverduePlanned = 21,
	OverdueNoAgreement = 22
}

export enum WellState {
	Active = 'Active',
	Inactive = 'Inactive',
	Deleted = 'Deleted'
}

export class DTBarrier {
	BarrierBrand?: string;
	BarrierPipeDiameter?: number;
	InstallationDirection?: string;
	BarrierType?: string;
	SerialNumber?: string;
}
