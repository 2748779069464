import Serializer from '@/Misc/Serializer';

export default class User implements IUser {
	Id: string = '';
	OrganizationId: string = '';
	Name?: string | undefined;
	Roles: string[] = [];
	Email: string = '';
	Phone?: string | undefined;
	Note?: string | undefined;
	Password?: string | undefined;
	InviteToken?: string | undefined;
	LatestLoginDate?: Date | undefined;
	Status: UserState = UserState.Active;
	ServiceNotificationEmail: boolean = false;

	public constructor(init?: Partial<User>) {
		Object.assign(this, init);
	}

	public get Role(): RoleTypes {
		if (this.Roles.find((r) => r == 'Administrator')) {
			return RoleTypes.SystemAdministrator;
		}

		if (this.Roles.find((r) => r == 'Owner')) {
			return RoleTypes.Administrator;
		}

		return RoleTypes.Technician;
	}

	public set Role(value: RoleTypes) {
		this.Roles = [value.toString()];
	}

	public Deserialize() {
		Serializer.StringToDate(['LatestLoginDate'], this);
	}

	public get IsSysAdmin(): boolean {
		if (!this.Roles) {
			return false;
		}

		if (this.Roles.find((v) => v == 'Administrator')) {
			return true;
		}

		return false;
	}

	public get IsAdministrator(): boolean {
		if (!this.Roles) {
			return false;
		}

		for (let i = 0; i < this.Roles.length; i++) {
			const role = this.Roles[i];
			if (role === 'Owner') {
				return true;
			}

			if (role === 'Administrator') {
				return true;
			}
		}

		return false;
	}

	public RoleDisplayName() {
		if (this.Roles.find((r) => r == 'Administrator')) {
			return 'Systemadministrator';
		}

		if (this.Roles.find((r) => r == 'Owner')) {
			return 'Administrator';
		}

		return 'Montør';
	}
}

export interface IUser {
	Id: string; // Cannot be null
	OrganizationId: string; // Cannot be null
	Name?: string;
	Roles: string[]; // Cannot be null
	Email: string; // Cannot be null
	Phone?: string;
	Note?: string;
	Password?: string;
	InviteToken?: string;
	LatestLoginDate?: Date;
	Status: UserState;
	ServiceNotificationEmail: boolean;
}

export enum UserState {
	Active = 'Active',
	Inactive = 'Inactive',
	Deleted = 'Deleted',
	Invited = 'Invited',
	WaitForConfirmation = 'WaitForConfirmation'
}

export enum RoleTypes {
	Administrator = 'Owner',
	Technician = 'Technician',
	SystemAdministrator = 'Administrator'
}
