import RemoteActions from './RemoteActions';
import UI from './UI';
import State from './State';
import RemotePoller from './RemotePoller';

export default class Backbone {
	private static _instance: Backbone;

	private _remoteActions: RemoteActions = new RemoteActions();
	public static get RemoteActions(): RemoteActions {
		return Backbone.instance._remoteActions;
	}
	private _ui: UI = new UI();
	public static get UI(): UI {
		return Backbone.instance._ui;
	}
	private _state: State = new State();
	public static get State(): State {
		return Backbone.instance._state;
	}

	private _poller: RemotePoller = new RemotePoller();
	public static get RemotePoller(): RemotePoller {
		return Backbone.instance._poller;
	}

	public static get instance(): Backbone {
		if (!Backbone._instance) {
			Backbone._instance = new Backbone();
		}
		return Backbone._instance;
	}
}

//export default
