import { AxiosResponse } from 'axios';
import RemoteActions from './RemoteActions';
import DTBannerInfo from '@/models/DTBannerInfo';

export default class RA_Banner {
	private remoteActions: RemoteActions;

	constructor(remoteActions: RemoteActions) {
		this.remoteActions = remoteActions;
	}

	public async GetAll(): Promise<AxiosResponse<DTBannerInfo[]>> {
		const response = await this.remoteActions.Get<DTBannerInfo[]>(
			() => new DTBannerInfo(),
			this.remoteActions.apiUrl + 'mapi/banner/banners',
			undefined,
			true
		);

		return response;
	}

	public async CreateOrPutBanner(banner: DTBannerInfo, bannerData: File): Promise<AxiosResponse<DTBannerInfo>> {
		let response: AxiosResponse<DTBannerInfo> | null = null;

		if (banner.Id) {
			response = await this.remoteActions.Put<DTBannerInfo>(
				() => new DTBannerInfo(),
				this.remoteActions.apiUrl + 'mapi/banner/modify',
				banner,
				undefined,
				true
			);
		} else {
			const fdata: FormData = new FormData();
			fdata.append('url', banner.Url!.toString());
			fdata.append('name', banner.Name!.toString());
			fdata.append('note', banner.Note!.toString());
			fdata.append('state', banner.State!.toString());
			fdata.append('uploadedFile', bannerData);
			response = await this.remoteActions.Post<DTBannerInfo>(
				() => new DTBannerInfo(),
				this.remoteActions.apiUrl + 'mapi/banner/upload',
				fdata,
				undefined,
				true
			);
		}
		return response;
	}

	public GetFilePath(downloadKey: string): string {
		if (downloadKey == '') {
			return '/gfx/photo_broken.png';
		}
		return this.remoteActions.apiUrl + 'mapi/banner/fetch/' + downloadKey;
	}

	public async Delete(bannerId: string): Promise<AxiosResponse<DTBannerInfo>> {
		const response: AxiosResponse<DTBannerInfo> = await this.remoteActions.Delete<DTBannerInfo>(
			() => new DTBannerInfo(),
			this.remoteActions.apiUrl + 'mapi/banner/' + bannerId,
			undefined,
			true
		);
		return response;
	}
}
