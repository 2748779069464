import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/LoginForm.vue';

Vue.use(Router);

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: () => import(/* webpackChunkName: "about" */ './layouts/centered.vue'),
			children: [
				{
					path: '',
					name: 'home',
					component: Login
				},
				{
					path: '/login',
					name: 'login',
					component: Login
				},
				{
					path: '/resetpwd',
					name: 'Skift password',
					component: () => import(/* webpackChunkName: "about" */ './views/ResetPassword.vue')
				},
				{
					path: '/created-use-app',
					name: 'Oprettet',
					component: () => import(/* webpackChunkName: "about" */ './views/CreatedUseApp.vue')
				},
				{
					path: '/invite',
					name: 'Tilmelding til organisation',
					component: () => import(/* webpackChunkName: "about" */ './views/UserAssignForm.vue')
				},
				{
					path: '/create-organisation',
					name: 'createorg',
					// route level code-splitting
					// this generates a separate chunk (about.[hash].js) for this route
					// which is lazy-loaded when the route is visited.
					component: () => import(/* webpackChunkName: "about" */ './views/CreateOrg.vue')
				},
				{
					path: '/confirm',
					name: 'Bekræft organisation',
					component: () => import(/* webpackChunkName: "about" */ './views/ConfirmOrg.vue')
				},
				{
					path: '/support',
					name: 'Hjælp',
					component: () => import(/* webpackChunkName: "about" */ './views/SupportPage.vue')
				}
			]
		},
		{
			path: '/',
			component: () => import(/* webpackChunkName: "about" */ './layouts/baseline.vue'),
			children: [
				{
					path: '/users-admin',
					name: 'Medarbejdere',
					component: () => import(/* webpackChunkName: "about" */ './views/UsersAdmin.vue')
				},
				{
					path: '/addresslist',
					name: 'Kunder',
					component: () => import(/* webpackChunkName: "about" */ './views/Address/AddressList.vue')
				},
				{
					path: '/companylist',
					name: 'Kundegruppe',
					component: () => import(/* webpackChunkName: "about" */ './views/CompanyList.vue')
				},
				{
					path: '/orgsettings',
					name: 'Indstillinger',
					component: () => import(/* webpackChunkName: "about" */ './views/OrgSettings.vue')
				},
				{
					path: '/organisationsadmin',
					name: 'Organisationer',
					component: () => import('./views/OrganisationsAdmin.vue')
				},
				{
					path: '/confirmprivacypolicy',
					name: 'Nordisk Innovations Privatlivspolitik',
					component: () => import('./views/ConfirmPrivacyPolicy.vue')
				},
				{
					path: '/banners',
					name: 'Bannneropsætning',
					component: () => import('./views/Banners.vue')
				},
				{
					path: '/notifications',
					name: 'Notifikationer',
					component: () => import('./views/WebNotifications.vue')
				}
			]
		},
		{
			path: '/',
			component: () => import(/* webpackChunkName: "about" */ './layouts/print.vue'),
			children: [
				// {
				// 	path: '/servicerapport',
				// 	name: 'servicereport',
				// 	component: () => import(/* webpackChunkName: "about" */ './views/ServiceReport.vue')
				// },
			]
		},
		{
			path: '/genqrcodes',
			component: () => import('./Misc/QRCodeGenerator.vue')
		}
	]
});
