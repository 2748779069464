import { AxiosResponse } from 'axios';
import RemoteActions from './RemoteActions';

export default class RA_ServiceVisit {
	private remoteActions: RemoteActions;

	constructor(remoteActions: RemoteActions) {
		this.remoteActions = remoteActions;
	}

	public async ServiceVisitByKey(key: string): Promise<AxiosResponse<any>> {
		const response = await this.remoteActions.Get<any>(
			() => new Object(),
			this.remoteActions.apiUrl + 'mapi/servicevisit/bykey/' + key,
			undefined,
			true
		);
		return response;
	}
}
