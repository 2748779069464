import DTLocation from './DTLocation';
import Serializer from '@/Misc/Serializer';
import moment, { Moment } from 'moment';
import { EServiceStatus } from './Address';
import Backbone from '@/core/Backbone';

export default class ScheduledVisit implements IScheduledVisit {
	Id?: string | undefined;
	CreatedDate?: Date | undefined;
	ModifiedDate?: Date | undefined;
	OrganizationId?: string | undefined;
	CustomerId?: string | undefined;
	UserId?: string | undefined;
	TaskStartDate: Moment = moment(-8640000000000);
	TaskEndDate: Moment = moment(-8640000000000);
	Note?: string | undefined;
	State: EServiceTaskState = EServiceTaskState.Active;
	CustomerName?: string | undefined;
	CustomerAddress?: string | undefined;
	Location: DTLocation = new DTLocation();
	UserName?: string | undefined;
	InstallationIds: string[] = [];

	public constructor(init?: Partial<ScheduledVisit>) {
		Object.assign(this, init);
		this.Deserialize();
	}

	public ServiceStatus: EServiceStatus = EServiceStatus.DonePlanned;

	public Deserialize() {
		Serializer.StringToDate(['CreatedDate', 'ModifiedDate', 'TaskStartDate', 'TaskEndDate'], this);
		Serializer.StringToLocation('Location', this);
		Serializer.StringToMoment(['TaskStartDate', 'TaskEndDate'], this);

		const dateDiff = this.TaskStartDate.diff(moment(), 'days');
		if (dateDiff < 0) {
			this.ServiceStatus = EServiceStatus.OverduePlanned;
		} else if (dateDiff < Backbone.State.Organisation.ServiceSoonPeriodDays) {
			this.ServiceStatus = EServiceStatus.SoonPlanned;
		} else {
			this.ServiceStatus = EServiceStatus.DonePlanned;
		}
	}
}

export interface IScheduledVisit {
	Id?: string;
	CreatedDate?: Date;
	ModifiedDate?: Date;
	OrganizationId?: string;
	CustomerId?: string;
	UserId?: string;
	TaskStartDate: Moment;
	TaskEndDate: Moment;
	Note?: string;
	State: EServiceTaskState;
	CustomerName?: string;
	CustomerAddress?: string;
	Location: DTLocation;
	UserName?: string;
	InstallationIds: string[];
}

export enum EServiceTaskState {
	Active = 'Active',
	Inactive = 'Inactive',
	Deleted = 'Inactive'
}
