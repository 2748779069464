export default class DTLocation {
	public get Lat(): number {
		return this.lat;
	}
	public set Lat(value: number) {
		this.lat = value;
	}
	public get Lng(): number {
		return this.lng;
	}
	public set Lng(value: number) {
		this.lng = value;
	}

	private lat: number = 55.382035;
	private lng: number = 10.38599;

	public constructor(init?: Partial<DTLocation>) {
		if (init) {
			Object.assign(this, init);
		}
	}
}
