import Address from './Address';
import Serializer from '@/Misc/Serializer';

export default class CustomerSearchResult {
	public PageNumber: string = '';
	public NumberOfPages: number = 0;
	public NumberOfItems: number = 0;
	public PageSize: number = 0;
	public Items: Address[] = [];

	public Deserialize() {
		Serializer.Deserialize(this.Items, () => new Address());
	}
}
