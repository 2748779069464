import { AxiosResponse } from 'axios';
import RemoteActions from './RemoteActions';
import ScheduledVisit from '@/models/ScheduledVisit';
import { Moment } from 'moment';
const querystring = require('querystring');

export default class RA_ScheduledVisit {
	private remoteActions: RemoteActions;

	constructor(remoteActions: RemoteActions) {
		this.remoteActions = remoteActions;
	}

	public async CreateScheduledVisit(data: ScheduledVisit): Promise<AxiosResponse<ScheduledVisit>> {
		const response = await this.remoteActions.Post<ScheduledVisit>(
			() => new ScheduledVisit(),
			this.remoteActions.apiUrl + 'mapi/scheduledvisit',
			data,
			undefined,
			true
		);
		return response;
	}

	public async UpdateScheduledVisit(data: ScheduledVisit): Promise<AxiosResponse<ScheduledVisit>> {
		const response = await this.remoteActions.Put<ScheduledVisit>(
			() => new ScheduledVisit(),
			this.remoteActions.apiUrl + 'mapi/scheduledvisit',
			data,
			undefined,
			true
		);

		return response;
	}

	public async ServiceVisitsForUser(userId: string, fromDate: Date, toDate: Date): Promise<AxiosResponse<ScheduledVisit[]>> {
		const q = querystring.encode({
			fromDate: fromDate.toISOString(),
			toDate: toDate.toISOString()
		});

		const response = await this.remoteActions.Get<ScheduledVisit[]>(
			() => new ScheduledVisit(),
			this.remoteActions.apiUrl + 'mapi/scheduledVisit/user/' + userId + '?' + q,
			undefined,
			true
		);
		return response;
	}

	public async DeleteScheduledVisit(visitId: string): Promise<AxiosResponse<any>> {
		const response = await this.remoteActions.Delete<any>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/scheduledvisit/' + visitId,
			undefined,
			true
		);

		return response;
	}

	public async ScheduledVisitsForCustomer(customerId: string, from: Moment | undefined): Promise<AxiosResponse<ScheduledVisit[]>> {
		const q = querystring.encode({
			fromDate: from ? from.toISOString() : undefined
		});

		const response = await this.remoteActions.Get<ScheduledVisit[]>(
			() => new ScheduledVisit(),
			this.remoteActions.apiUrl + 'mapi/scheduledvisit/customer/' + customerId + '?' + q,
			undefined,
			true
		);
		return response;
	}
}
