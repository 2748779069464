export default class Organisation implements IOrganisation {
	Id?: string;
	Name?: string;
	CVRnumber: string = '';
	Address: string = '';
	ContactName: string = '';
	ContactEmail: string = '';
	Phonenumber: string = '';
	Newsletter: boolean = false;
	Note?: string;
	State: State = State.Inactive;
	Logo?: string = '';
	ServiceSoonPeriodDays: number = 60;
	PrivacyPolicyAgreed: boolean = false;
	ReportEmail: string = '';

	public get AddressOneLine(): string {
		if (!this.Address) {
			this.Address = '';
		}
		const result = this.Address.replace('\n', ', ');
		return result;
	}
}

export interface IOrganisation {
	Id?: string;
	Name?: string;
	CVRnumber?: string;
	Address?: string;
	ContactName?: string;
	ContactEmail?: string;
	Phonenumber?: string;
	Newsletter?: boolean;
	Note?: string;
	State: State;
	Logo?: string;
	ServiceSoonPeriodDays: number;
	PrivacyPolicyAgreed: boolean;
}

export enum State {
	Active = 'Active',
	Inactive = 'Inactive',
	Deleted = 'Deleted',
	WatingForConfirmation = 'WatingForConfirmation',
	MarkedForDeletion = 'MarkedForDeletion'
}
