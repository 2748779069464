import { AxiosResponse } from 'axios';
import RemoteActions from './RemoteActions';
import Address, { ICustomer } from '@/models/Address';
import Well from '@/models/Well';
const querystring = require('querystring');
import CustomerSearchResult from '@/models/CustomerSearchResult';
import Report from '@/models/Reports';
import DTCompany from '@/models/DTCompany';

export default class RA_Customer {
	private remoteActions: RemoteActions;

	constructor(remoteActions: RemoteActions) {
		this.remoteActions = remoteActions;
	}

	public async Get(customerId: string): Promise<AxiosResponse<Address>> {
		const r = await this.remoteActions.Get<Address>(
			() => new Address(),
			this.remoteActions.apiUrl + 'mapi/customer/' + customerId,
			undefined,
			true
		);

		return r;
	}

	public async GetAll(): Promise<AxiosResponse<Address[]>> {
		const response = await this.remoteActions.Get<Address[]>(
			() => new Address(),
			this.remoteActions.apiUrl + 'mapi/customer',
			undefined,
			true
		);

		return response;
	}

	public async CreateOrPut(customer: ICustomer): Promise<AxiosResponse<Address>> {
		let response: AxiosResponse<Address>;
		if (customer.Id) {
			response = await this.remoteActions.Put<Address>(
				() => new Address(),
				this.remoteActions.apiUrl + 'mapi/customer',
				customer,
				undefined,
				true
			);
		} else {
			response = await this.remoteActions.Post<Address>(
				() => new Address(),
				this.remoteActions.apiUrl + 'mapi/customer',
				customer,
				undefined,
				true
			);
		}

		return response;
	}

	public async GetWells(customerId: string): Promise<AxiosResponse<Well[]>> {
		const response = await this.remoteActions.Get<Well[]>(
			() => new Well(),
			this.remoteActions.apiUrl + 'mapi/customer/' + customerId + '/installations',
			undefined,
			true
		);

		return response;
	}

	public async GetWell(installationId: string): Promise<AxiosResponse<Well>> {
		const response = await this.remoteActions.Get<Well>(
			() => new Well(),
			this.remoteActions.apiUrl + 'mapi/installation/' + installationId,
			undefined,
			true
		);

		return response;
	}

	public async GetReports(customerId: string): Promise<AxiosResponse<Report[]>> {
		const response = await this.remoteActions.Get<Report[]>(
			() => new Report(),
			this.remoteActions.apiUrl + 'mapi/customer/' + customerId + '/reports',
			undefined,
			true
		);

		return response;
	}

	public async Search(
		userIds: string[],
		estimatedServiceWithinTwoMonths: boolean,
		exceededService: boolean,
		estimatedServiceInMoreThanTwoMonths: boolean,
		noServiceAgreement: boolean,
		hasPlannedService: boolean,
		text: string,
		page: number = 1,
		pageSize: number = 5000
	): Promise<AxiosResponse<CustomerSearchResult>> {
		const q = querystring.encode({
			estimatedServiceWithinTwoMonths,
			exceededService,
			estimatedServiceInMoreThanTwoMonths,
			noServiceAgreement,
			hasPlannedService,
			pageSize,
			text,
			page,
			userIds: userIds
		});

		const response = await this.remoteActions.Get<CustomerSearchResult>(
			() => new CustomerSearchResult(),
			this.remoteActions.apiUrl + 'mapi/customer/search?' + q,
			undefined,
			true
		);

		return response;
	}

	public async SearchText(text: string): Promise<AxiosResponse<CustomerSearchResult>> {
		const q = querystring.encode({
			text
		});

		const response = await this.remoteActions.Get<CustomerSearchResult>(
			() => new CustomerSearchResult(),
			this.remoteActions.apiUrl + 'mapi/customer/searchtext?' + q,
			undefined,
			true
		);

		return response;
	}

	public async Delete(customerId: string): Promise<AxiosResponse<Address>> {
		const response = await this.remoteActions.Delete<Address>(
			() => new Address(),
			this.remoteActions.apiUrl + 'mapi/customer/' + customerId,
			undefined,
			true
		);

		return response;
	}

	public async DeleteInstallation(installationId: string): Promise<AxiosResponse<Well>> {
		const response = await this.remoteActions.Delete<Well>(
			() => new Well(),
			this.remoteActions.apiUrl + 'mapi/installation/' + installationId,
			undefined,
			true
		);

		return response;
	}

	public async CreateOrPutInstallation(installation: Well): Promise<AxiosResponse<Well>> {
		let response: AxiosResponse<Well>;

		if (installation.Id) {
			response = await this.remoteActions.Put<Well>(
				() => new Well(),
				this.remoteActions.apiUrl + 'mapi/installation',
				installation,
				undefined,
				true
			);
		} else {
			response = await this.remoteActions.Post<Well>(
				() => new Well(),
				this.remoteActions.apiUrl + 'mapi/installation',
				installation,
				undefined,
				true
			);
		}

		return response;
	}

	// public async UploadServiceAgreement(customerId: string, data:File): Promise<AxiosResponse<any>> {
	// 	let fdata: FormData = new FormData();
	// 	fdata.append("uploadedFile", data)
	// 	let response: AxiosResponse<any> = await this.remoteActions.Post<any>(undefined, this.remoteActions.apiUrl + 'mapi/customer/' + customerId + "/serviceagreement", fdata, undefined, true);

	// 	return response;
	// }

	public async DeleteServiceAgreement(customerId: string): Promise<AxiosResponse<any>> {
		const response: AxiosResponse<any> = await this.remoteActions.Delete<any>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/customer/' + customerId + '/serviceagreement',
			undefined,
			true
		);

		return response;
	}

	public async CreateInstallationReport(installationId: string): Promise<AxiosResponse<Well>> {
		const response: AxiosResponse<Well> = await this.remoteActions.Post<Well>(
			() => new Well(),
			this.remoteActions.apiUrl + 'mapi/report/generateInstallationReport/' + installationId,
			{},
			undefined,
			true
		);

		return response;
	}

	// public async Task<RemoteResult<DTCompany>> DELETE_RemoveCustomerFromCompany(string companyId, string customerId)
	// 	{
	// 		if (!HasInternetAccess())
	// 		{
	// 			return RemoteResult<DTCompany>.CreateError("Ingen internetforbindelse", new Exception("No Internet"), HttpStatusCodeExt.NoInternet);
	// 		}
	// 		HttpResponseMessage response;
	// 		try
	// 		{
	// 			response = await restService.DELETE($"/mapi/company/{companyId}/RemoveCustomer?customerId={customerId}");
	// 		}
	// 		catch (Exception reqEx)
	// 		{
	// 			return RemoteResult<DTCompany>.CreateError(reqEx.Message, reqEx, HttpStatusCodeExt.ServiceUnavailable);
	// 		}
	// 		return await RemoteResult<DTCompany>.Create(response);
	// 	}

	public async RemoveCompanyToAddress(companyId: string, addressId: string): Promise<AxiosResponse> {
		const response: AxiosResponse<DTCompany> = await this.remoteActions.Delete(
			undefined,
			this.remoteActions.apiUrl + `mapi/company/${companyId}/RemoveCustomer?customerId=${addressId}`,
			undefined,
			true
		);

		return response;
	}

	public async AddCompanyToAddress(companyId: string, addressId: string): Promise<AxiosResponse> {
		const response: AxiosResponse<DTCompany> = await this.remoteActions.Post(
			undefined,
			this.remoteActions.apiUrl + `mapi/company/${companyId}/AddCustomer?customerId=${addressId}`,
			'',
			undefined,
			true
		);

		return response;
	}

	public async UpdateCompany(company: DTCompany): Promise<AxiosResponse<DTCompany>> {
		const response: AxiosResponse<DTCompany> = await this.remoteActions.Patch<DTCompany>(
			() => new DTCompany(),
			this.remoteActions.apiUrl + 'mapi/company/update',
			company,
			undefined,
			true
		);

		return response;
	}

	public async CreateCompany(company: DTCompany): Promise<AxiosResponse<DTCompany>> {
		(company.Id as any) = undefined; // otherwise this is "" which the backend don't like
		const response: AxiosResponse<DTCompany> = await this.remoteActions.Post<DTCompany>(
			() => new DTCompany(),
			this.remoteActions.apiUrl + 'mapi/company/create',
			company,
			undefined,
			true
		);

		return response;
	}

	public async DeleteCompany(companyId: string): Promise<AxiosResponse<DTCompany>> {
		const response: AxiosResponse<DTCompany> = await this.remoteActions.Delete(
			undefined,
			this.remoteActions.apiUrl + 'mapi/company/' + companyId,
			undefined,
			true
		);

		return response;
	}
}
