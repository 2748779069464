import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import da from './da';

import '@/assets/main.css';

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { da },
		current: 'da'
	},
	icons: {
		iconfont: 'mdi'
	}
});
