export default class TypeScriptUtils {
	public static async WaitUntil(conditionFunction: () => any) {
		const poll = (resolve: any) => {
			if (conditionFunction()) {
				resolve();
			} else {
				setTimeout(() => poll(resolve), 400);
			}
		};

		return new Promise(poll);
	}

	public static async WaitForSeconds(seconds: number) {
		return new Promise((res) => setTimeout(res, seconds * 1000));
	}

	public static isNullOrWhitespace(input: string | undefined): boolean {
		return !input || !input.trim();
	}
}
