if (location.port !== '8080' && location.protocol !== 'https:') {
	//location.protocol = 'https:';
	console.log('Redirecting to https');
}

import Vue from 'vue';
import App from './layouts/rootLayout.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import moment from 'moment';
import Backbone from './core/Backbone';
const VueGoogleMaps = require('vue2-google-maps');

Vue.config.productionTip = false;
moment.defineLocale('da-mod', {
	parentLocale: 'da',
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD-MM-YYYY',
		LL: 'D. MMMM YYYY',
		LLL: 'D. MMMM YYYY HH:mm',
		LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm'
	}
	/* */
});
moment.locale('da-mod');

const ignoredMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = (message, vm, componentTrace) => {
	if (message !== ignoredMessage) {
		console.error(message + componentTrace);
	}
};

async function Init() {
	// if (!await Backbone.State.InitState()) {

	// 	//What in the world is this??!!
	// 	if (document.location.pathname != "/")
	// 	{
	// 		console.log("Redirecting to login");
	// 		Backbone.RemotePoller.Stop();
	// 		document.location.href="/";
	// 	}
	// }

	new Vue({
		router,
		store,
		vuetify,
		render: (h) => h(App),
		data: {
			Backbone: Backbone.instance
		}
	}).$mount('#app');

	Vue.use(VueGoogleMaps, {
		load: {
			key: 'AIzaSyCjGkNkkD6Pi2zGFLlYgSH-58lBgmPAkV4',
			libraries: 'places' // This is required if you use the Autocomplete plugin
			// OR: libraries: 'places,drawing'
			// OR: libraries: 'places,drawing,visualization'
			// (as you require)

			//// If you want to set the version, you can do so:
			// v: '3.26',
		},

		//// If you intend to programmatically custom event listener code
		//// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
		//// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
		//// you might need to turn this on.
		// autobindAllEvents: false,

		//// If you want to manually install components, e.g.
		//// import {GmapMarker} from 'vue2-google-maps/src/dialogs/marker'
		//// Vue.component('GmapMarker', GmapMarker)
		//// then set installComponents to 'false'.
		//// If you want to automatically install all the components this property must be set to 'true':
		installComponents: true
	});
}

Init();
