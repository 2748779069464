/* eslint-disable no-console */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import RemoteActions from './RemoteActions';
import User from '@/models/User';
import Organisation from '@/models/Organisation';
const VersionInfo = require('@/VersionInfo.js').Version;

export default class RA_User {
	private remoteActions: RemoteActions;

	constructor(remoteActions: RemoteActions) {
		this.remoteActions = remoteActions;
	}

	public async Login(username: string, password: string): Promise<AxiosResponse<any>> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		const data: FormData = new FormData();
		data.append('username', username);
		data.append('password', password);
		data.append('clientIdentificator', 'web');
		data.append('clientVersion', VersionInfo.version);

		try {
			const response = await axios.post(this.remoteActions.apiUrl + 'v1/user/login', data, config);
			if (response.status < 300) {
				this.remoteActions.authToken = response.data.Token;
			}

			return response;
		} catch (e: any) {
			console.log(e.response);
			return e.response;
		}
	}

	public async Update(user: User): Promise<AxiosResponse<any>> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				Authorization: 'Bearer ' + this.remoteActions.authToken
			}
		};

		try {
			const response = await this.remoteActions.Patch<any>(undefined, this.remoteActions.apiUrl + 'v1/user', user, config);
			return response;
		} catch (e: any) {
			console.log(e.response);
			return e.response;
		}
	}

	public async Me(): Promise<AxiosResponse<User>> {
		const response = await this.remoteActions.Get<User>(() => new User(), this.remoteActions.apiUrl + 'v1/user/me', undefined, true);
		return response;
	}

	public async MyOrganisation(): Promise<AxiosResponse<Organisation>> {
		const response = await this.remoteActions.Get<Organisation>(
			() => new Organisation(),
			this.remoteActions.apiUrl + 'v1/user/myOrganization',
			undefined,
			true
		);
		return response;
	}

	public async Delete(userId: string): Promise<any> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				Authorization: 'Bearer ' + this.remoteActions.authToken
			}
		};

		try {
			const response = await axios.delete<any>(this.remoteActions.apiUrl + 'v1/user/' + userId, config);
			return response;
		} catch (e: any) {
			console.log(e.response);
			return e.response;
		}
	}
}
