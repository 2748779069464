import moment, { Moment } from 'moment';
import DTLocation from './DTLocation';
import Serializer from '@/Misc/Serializer';
import Backbone from '@/core/Backbone';
import Well, { EWellServiceStatus } from './Well';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import DTCompany from './DTCompany';

export default class Address implements ICustomer {
	public Id: string = '';
	public OrganizationId: string = '';
	public CustomerType: ECustomerType = ECustomerType.Company;
	public CompanyName?: string | undefined;
	public CVR?: string | undefined;
	public FirstName?: string | undefined;
	public LastName?: string | undefined;
	public Address?: string | undefined;
	public ZipCode?: string | undefined;
	public City?: string | undefined;
	public Location: DTLocation = new DTLocation();
	public EmailAddress?: string | undefined;
	public PhoneNumber?: string | undefined;
	public ContactPerson?: string | undefined;
	public CustomerNumber?: string | undefined;
	public Note?: string | undefined;
	public State: EAddressState = EAddressState.Active;
	public CreatedDate: Moment = moment(0);
	public ModifiedDate: Date = new Date();
	public Wells: Well[] = [];
	public HasServiceAgreement: boolean = true;
	public ServiceAgreementFileKey: string = '';
	public ServiceAgreementFileName: string = '';
	public CompanyId: string = '';
	public SkipServiceNotificationEmail: boolean = false;

	private _onChanged = new SimpleEventDispatcher<Address>();
	public get OnChanged() {
		return this._onChanged.asEvent();
	}
	private isPlanned: boolean = false;
	public get IsPlanned(): boolean {
		return this.isPlanned;
	}

	public NextEstimatedServiceDate: Moment = moment(8640000000000);
	//public LatestServiceVisit: Moment = moment(-8640000000000); //TODO moment - get from well
	public NextScheduledServiceVisit = {
		Id: '',
		DateStart: moment(),
		UserId: '',
		UserName: ''
	};

	public ServiceStatus: EServiceStatus = EServiceStatus.Done;
	public BasicServiceStatus: EWellServiceStatus = EWellServiceStatus.Done;
	private LatestServiceVisit: Moment = moment(0);

	private _company?: DTCompany;
	public get Company(): DTCompany | undefined {
		if (!this.CompanyId) {
			return undefined;
		}

		if (!this._company || this._company.Id != this.CompanyId) {
			this._company = Backbone.State.Companies.find((c) => c.Id == this.CompanyId);
		}

		return this._company;
	}

	public set Company(company: DTCompany | undefined) {
		if (company) {
			this.CompanyId = company.Id;
			this._company = company;
		} else {
			this.CompanyId = '';
			this._company = undefined;
		}
	}

	public constructor() {
		if (Backbone.State.User) {
			this.OrganizationId = Backbone.State.User.OrganizationId;
		}
	}

	public Deserialize() {
		Serializer.StringToDate(['CreatedDate', 'ModifiedDate'], this);
		Serializer.StringToLocation('Location', this);
		Serializer.StringToMoment(['CreatedDate'], this);
		this.UpdateData();
	}

	public UpdateData() {
		this.BasicServiceStatus = EWellServiceStatus.Done;
		this.NextEstimatedServiceDate = moment(8640000000000);
		this.NextScheduledServiceVisit.DateStart = moment(8640000000000);
		this.isPlanned = false;
		this.LatestServiceVisit = moment(0);

		if (this.Wells.length == 0) {
			this.LatestServiceVisit = moment(this.CreatedDate);
			this.NextEstimatedServiceDate = moment(this.LatestServiceVisit); //.add(12, 'months');
		}

		for (let i = 0; i < this.Wells.length; i++) {
			const well = this.Wells[i];

			if (well.LatestServiceVisit.isAfter(this.LatestServiceVisit)) {
				this.LatestServiceVisit = moment(well.LatestServiceVisit);
			}

			if (well.EstimatedServiceDate < this.NextEstimatedServiceDate) {
				this.NextEstimatedServiceDate = moment(well.EstimatedServiceDate);
			}

			if (well.IsPlanned) {
				this.isPlanned = true;
				if (well.NextPlannedServiceDate! < this.NextScheduledServiceVisit.DateStart) {
					this.NextScheduledServiceVisit.DateStart = well.NextPlannedServiceDate!;
					this.NextScheduledServiceVisit.Id = well.NextPlannedServiceId;
					this.NextScheduledServiceVisit.UserId = well.NextPlannedServiceUserId;
					this.NextScheduledServiceVisit.UserName = well.NextPlannedServiceUserName;
				}
			}

			if (this.BasicServiceStatus == EWellServiceStatus.Overdue) {
				continue;
			}

			if (well.ServiceStatus == EWellServiceStatus.Overdue) {
				this.BasicServiceStatus = EWellServiceStatus.Overdue;
				continue;
			}

			if (well.ServiceStatus == EWellServiceStatus.OverduePlanned) {
				this.BasicServiceStatus = EWellServiceStatus.OverduePlanned;
				continue;
			}
			if (this.BasicServiceStatus == EWellServiceStatus.OverduePlanned) {
				continue;
			}

			if (well.ServiceStatus == EWellServiceStatus.Soon) {
				this.BasicServiceStatus = EWellServiceStatus.Soon;
				continue;
			}
			if (this.BasicServiceStatus == EWellServiceStatus.Soon) {
				continue;
			}

			if (well.ServiceStatus == EWellServiceStatus.SoonPlanned) {
				this.BasicServiceStatus = EWellServiceStatus.SoonPlanned;
				continue;
			}
			if (this.BasicServiceStatus == EWellServiceStatus.SoonPlanned) {
				continue;
			}

			if (well.ServiceStatus == EWellServiceStatus.DonePlanned) {
				this.BasicServiceStatus = EWellServiceStatus.DonePlanned;
				continue;
			}
			if (this.BasicServiceStatus == EWellServiceStatus.DonePlanned) {
				continue;
			}
			// no need to check for done unplanned
		}
		this.ServiceStatus = <EServiceStatus>(<number>this.BasicServiceStatus);
		if (!this.HasServiceAgreement && !this.IsPlanned) {
			switch (this.BasicServiceStatus) {
				case EWellServiceStatus.Done:
					this.ServiceStatus = EServiceStatus.DoneNoAgreement;
					break;
				case EWellServiceStatus.Soon:
					this.ServiceStatus = EServiceStatus.SoonNoAgreement;
					break;
				case EWellServiceStatus.Overdue:
					this.ServiceStatus = EServiceStatus.OverdueNoAgreement;
					break;
				case EWellServiceStatus.DonePlanned:
					this.ServiceStatus = EServiceStatus.DoneNoAgreement;
					break;
			}
		} else {
			this.ServiceStatus = <number>this.BasicServiceStatus;
		}

		this._onChanged.dispatch(this);
	}

	public get DisplayName(): string {
		if (this.CustomerType == ECustomerType.Company) {
			let result = this.CompanyName!;
			if (this.CVR) {
				result += ' (' + this.CVR + ')';
			}
			return result;
		}

		if (this.FirstName && this.LastName) {
			return this.FirstName + ' ' + this.LastName;
		}

		if (!this.FirstName && !this.LastName) {
			return 'n/a';
		}

		if (this.FirstName) {
			return this.FirstName;
		}

		if (this.LastName) {
			return this.LastName;
		}

		return 'n/a';
	}

	public get FullAddressOneLine(): string {
		const city = this.ZipAndCity;

		if (this.Address && city) {
			return this.Address + ', ' + city;
		}

		if (this.Address) {
			return this.Address;
		}

		if (city) {
			return city;
		}

		return '';
	}

	public get ZipAndCity(): string | undefined {
		if (this.ZipCode && this.City) {
			return this.ZipCode + ' ' + this.City;
		}

		if (this.ZipCode) {
			return this.ZipCode;
		}

		if (this.City) {
			return this.City;
		}

		return undefined;
	}

	public get FullAddressTwoLines(): string {
		const city = this.ZipAndCity;

		if (this.Address && city) {
			return this.Address + '<br />' + city;
		}

		if (this.Address) {
			return this.Address;
		}

		if (city) {
			return city;
		}

		return '';
	}
}

export interface ICustomer {
	Id: string; //Cannot be null
	OrganizationId: string; //Cannot be null
	CustomerType: ECustomerType; //Should be enum? + Cannot be null
	CompanyName?: string;
	CVR?: string;
	FirstName?: string;
	LastName?: string;
	Address?: string;
	ZipCode?: string;
	City?: string;
	Location: DTLocation;
	EmailAddress?: string;
	PhoneNumber?: string;
	ContactPerson?: string;
	CustomerNumber?: string;
	Note?: string;
	State: EAddressState; // This has changed to anum and cannot be null
	CreatedDate: Moment; //Cannot be null
	ModifiedDate: Date; //Cannot be null
	HasServiceAgreement: boolean;
	ServiceAgreementFileKey: string;
	ServiceAgreementFileName: string;
	Wells: Well[];
	SkipServiceNotificationEmail: boolean;
}

export enum EServiceStatus {
	Done = 0,
	DonePlanned = 1,
	DoneNoAgreement = 2,
	Soon = 10,
	SoonPlanned = 11,
	SoonNoAgreement = 12,
	Overdue = 20,
	OverduePlanned = 21,
	OverdueNoAgreement = 22
}

export enum EAddressState {
	Active = 'Active',
	Inactive = 'Inactive',
	Deleted = 'Deleted'
}

export enum ECustomerType {
	Private = 'Private',
	Company = 'Company'
}
