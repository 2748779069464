import moment from 'moment';
import Serializer from '@/Misc/Serializer';
import Backbone from '@/core/Backbone';
import { SimpleEventDispatcher } from 'strongly-typed-events';
import { EAddressState } from './Address';
import TypeScriptUtils from '@/Misc/TypeScriptUtils';

export default class DTCompany {
	// FROM API
	public Id: string = '';
	public OrganizationId: string = '';
	public Name: string = '';
	public CVR: string = '';
	public CustomerNumber: string = '';
	public State: EAddressState = EAddressState.Active;
	public CreatedDate: moment.Moment = moment();
	public ModifiedDate: moment.Moment = moment();
	public CreatedByUserId: string = '';
	public CreatedByUserName: string = '';
	public ContactName: string = '';
	public ContactEmails: string = '';
	public SendReportsToContactEmails: boolean = false;
	public CustomerIds: string[] = [];
	public Address: string = '';
	public ZipCode: string = '';
	public City: string = '';
	public PhoneNumber: string = '';

	private _onChanged = new SimpleEventDispatcher<DTCompany>();
	public get OnChanged() {
		return this._onChanged.asEvent();
	}
	public get AddressCount() {
		return this.CustomerIds.length;
	}

	public constructor() {
		if (Backbone.State.User) {
			this.OrganizationId = Backbone.State.User.OrganizationId;
		}
	}

	public Deserialize() {
		Serializer.StringToMomentWithFallback('CreatedDate', this, moment());
		Serializer.StringToMomentWithFallback('ModifiedDate', this, moment());
		this._onChanged.dispatch(this);
	}

	public get AddressOneLine() {
		const hAdr = !TypeScriptUtils.isNullOrWhitespace(this.Address);
		const hCity = !TypeScriptUtils.isNullOrWhitespace(this.ZipAndCity);
		if (hAdr && hCity) {
			return `${this.Address}, ${this.ZipAndCity}`;
		}

		if (hAdr) {
			return this.Address;
		}
		if (hCity) {
			return this.ZipAndCity;
		}

		return '';
	}

	public get AddressMultiLine() {
		return `${this.Address}<br />${this.ZipAndCity}`;
	}

	public get ZipAndCity() {
		return this.ZipCode + ' ' + this.City;
	}
}
