import moment, { Moment } from 'moment';
import Serializer from '@/Misc/Serializer';

export default class DTNotification {
	public Id: string = '';
	public Name: string = '';
	public CreatedDate: Moment = moment();
	public ModifiedDate: Moment = moment();
	public State: 'Waiting' | 'Published' | 'Unpublished' | 'Deactivated' | 'Deleted' = 'Published';
	public Content: string = '';
	public Extras: { [key: string]: string } = {};

	public Deserialize() {
		Serializer.StringToMoment(['CreatedDate', 'ModifiedDate'], this);
	}
}
