import moment, { Moment } from 'moment';
import Backbone from './Backbone';
import TypeScriptUtils from '@/Misc/TypeScriptUtils';

export default class RemotePoller {
	public State: State = State.Stopped;
	public LoopPolling: boolean = true;
	public dataTimeStamp: Moment = moment(0);
	private timer: number | null = null;

	public async Start(awaitForPollToFinish: boolean = true) {
		if (this.State != State.Stopped) {
			return;
		}

		this.LoopPolling = true;

		if (awaitForPollToFinish) {
			await this.ExecutePoll();
		} else {
			this.ExecutePoll();
		}
	}

	public async Stop() {
		await this.WaitForPollToFinish();

		this.LoopPolling = false;
		if (this.timer != null) {
			window.clearTimeout(this.timer);
		}
		if (this.State == State.WaitingForNextPoll) {
			this.State = State.Stopped;
		}
	}

	public async PollNow(awaitForPollToFinish: boolean = true) {
		await this.WaitForPollToFinish();
		if (this.timer != null) {
			window.clearTimeout(this.timer);
		}
		if (awaitForPollToFinish) {
			await this.ExecutePoll();
		} else {
			this.ExecutePoll();
		}
	}

	public async WaitForPollToFinish() {
		await TypeScriptUtils.WaitUntil(() => {
			return this.State != State.Polling;
		});
	}

	private async ExecutePoll() {
		if (this.timer != null) {
			window.clearTimeout(this.timer);
		}

		this.State == State.Polling;
		const result = await Backbone.RemoteActions.Other.PollData(this.dataTimeStamp);

		if (result.status < 300) {
			Backbone.State.UpdateFromPollData(result.data.Customers, result.data.Installations, result.data.Companies);
			this.dataTimeStamp = moment(result.data.TimeStamp);
		}

		if (this.LoopPolling) {
			this.State == State.WaitingForNextPoll;
			this.timer = window.setTimeout(() => {
				this.ExecutePoll();
			}, 600000);
		} else {
			this.State == State.Stopped;
		}
	}
}

export enum State {
	Stopped,
	WaitingForNextPoll,
	Polling
}
