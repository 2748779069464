import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import Progress from '@/dialogs/Progress.vue';
import Alerts from '@/dialogs/Alerts.vue';

export default class UI {
	private confirmDialog: ConfirmDialog | undefined;
	private _progressDialog: Progress | null = null;

	public get Progress(): Progress {
		return this._progressDialog!;
	}

	public set Progress(value: Progress) {
		this._progressDialog = value;
	}

	public SetConfirmDialog(dlg: ConfirmDialog) {
		this.confirmDialog = dlg;
	}

	public async OpenConfirmDialog(title: string, message: string, showCancelBtn: boolean, options?: any): Promise<boolean> {
		if (this.confirmDialog) {
			return await (this.confirmDialog as any).open(title, message, showCancelBtn, options);
		}

		return false;
	}

	private alerts: Alerts | undefined;

	public SetAlerts(alerts: Alerts) {
		this.alerts = alerts;
	}
	public PushAlert(text: string, type: 'success' | 'info' | 'warning' | 'error', dismissible: boolean) {
		if (this.alerts) {
			(this.alerts as any).Push(text, type, dismissible);
		}
	}

	public PushApiErrorAlert(text: string, error: any, type: 'success' | 'info' | 'warning' | 'error', dismissible: boolean) {
		if (this.alerts) {
			(this.alerts as any).PushApiError(text, error, type, dismissible);
		}
	}

	public GetHighestZIndex(): number {
		const queryObject = document.querySelectorAll('*');
		const childNodes = Object.keys(queryObject).map((key: any) => queryObject[key]);
		let highest = 0;

		childNodes.forEach((node: any) => {
			// Get the calculated CSS z-index value.
			const cssStyles = document!.defaultView!.getComputedStyle(node);
			let cssZIndex = cssStyles.getPropertyValue('z-index');

			// Get any inline z-index value.
			let inlineZIndex = node.style.zIndex;

			// Coerce the values as integers for comparison.
			cssZIndex = '' + (this.isNumeric(cssZIndex) ? parseInt(cssZIndex, 10) : 0);
			inlineZIndex = this.isNumeric(inlineZIndex) ? parseInt(inlineZIndex, 10) : 0;

			// Take the highest z-index for this element, whether inline or from CSS.
			const currentZIndex = cssZIndex > inlineZIndex ? cssZIndex : inlineZIndex;

			if (currentZIndex > highest) {
				highest = currentZIndex;
			}
		});

		return highest;
	}

	isNumeric(val: any) {
		return !isNaN(parseFloat(val)) && isFinite(val);
	}
}
