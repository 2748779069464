import { AxiosRequestConfig, AxiosResponse } from 'axios';
import RemoteActions from './RemoteActions';
import Organisation from '@/models/Organisation';
import User from '@/models/User';
import Address from '@/models/Address';
import Well from '@/models/Well';
import moment from 'moment';
import FileInfo from '@/models/FileInfo';
import DTCompany from '@/models/DTCompany';
import DTNotification from '@/models/DTNotification';
const querystring = require('querystring');

export default class RA_Other {
	private remoteActions: RemoteActions;

	constructor(remoteActions: RemoteActions) {
		this.remoteActions = remoteActions;
	}

	public async LookUpGps(address: string) {
		const config: AxiosRequestConfig = { responseType: 'text', headers: { 'Content-Type': 'text/json' } };
		const r = await this.remoteActions.Post<any>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/googlegeocoding',
			'"' + address + '"',
			config,
			true
		);
		return r;
	}

	public async GetAllOrganisations(): Promise<AxiosResponse<Organisation[]>> {
		const response = await this.remoteActions.Get<Organisation[]>(
			() => new Organisation(),
			this.remoteActions.apiUrl + 'mapi/organization',
			undefined,
			true
		);

		return response;
	}

	public async GetOrganisationUsers(orgId: string): Promise<AxiosResponse<User[]>> {
		const response = await this.remoteActions.Get<User[]>(
			() => new User(),
			this.remoteActions.apiUrl + 'mapi/organization/' + orgId + '/users',
			undefined,
			true
		);

		return response;
	}

	public async GetOrganisationCustomers(orgId: string): Promise<AxiosResponse<Address[]>> {
		const response = await this.remoteActions.Get<Address[]>(
			() => new Address(),
			this.remoteActions.apiUrl + 'mapi/organization/' + orgId + '/customers',
			undefined,
			true
		);

		return response;
	}

	public async LogInAs(username: string) {
		const q = querystring.encode({
			username: username
		});
		const response = await this.remoteActions.Post<any>(undefined, this.remoteActions.apiUrl + 'v1/user/loginAs', q, undefined, true);

		return response;
	}

	public async PollData(timestamp: moment.Moment): Promise<AxiosResponse<PollData>> {
		const response = await this.remoteActions.Get<PollData>(
			() => new PollData(),
			this.remoteActions.apiUrl + 'mapi/organization/pollData?sinceDate=' + timestamp.toISOString(),
			undefined,
			true
		);

		return response;
	}

	public async UploadFile(fileType: UploadFileTypes, data: File, sectionTag?: string): Promise<AxiosResponse<FileInfo>> {
		const fdata: FormData = new FormData();
		fdata.append('uploadedFile', data);
		let query = '?uploadType=' + fileType;
		if (sectionTag) {
			query += '&sectionTag=' + sectionTag;
		}

		const response: AxiosResponse<any> = await this.remoteActions.Post<FileInfo>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/uploadFile' + query,
			fdata,
			undefined,
			true
		);

		return response;
	}

	public async DeleteFile(downloadKey: string): Promise<AxiosResponse<any>> {
		const response: AxiosResponse<any> = await this.remoteActions.Delete<any>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/file/' + downloadKey,
			undefined,
			true
		);

		return response;
	}

	public async PutOrganisation(orgData: Organisation): Promise<AxiosResponse<Organisation>> {
		const response = await this.remoteActions.Put<Organisation>(
			() => new Organisation(),
			this.remoteActions.apiUrl + 'mapi/organization',
			orgData,
			undefined,
			true
		);

		return response;
	}

	public async MarkOrgForDeletion(orgId: string): Promise<AxiosResponse<any>> {
		const response: AxiosResponse<any> = await this.remoteActions.Delete<any>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/dataapi/markOrganizationForDeletion/' + orgId,
			undefined,
			true
		);
		return response;
	}

	public async WipeOrganisationData(orgId: string): Promise<AxiosResponse<any>> {
		const response = await this.remoteActions.Put<any>(
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			() => {},
			this.remoteActions.apiUrl + 'mapi/dataapi/wipeOrganizationData/' + orgId,
			undefined,
			undefined,
			true
		);

		return response;
	}

	public async GenerateOrgArchive(): Promise<AxiosResponse<string>> {
		const config: AxiosRequestConfig = { responseType: 'text' };
		const response = await this.remoteActions.Get<string>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/dataapi/generateOrganizationArchive',
			config,
			true
		);

		return response;
	}

	public async PollOrgArchive(key: string): Promise<AxiosResponse<boolean>> {
		const config: AxiosRequestConfig = { responseType: 'text' };
		const response = await this.remoteActions.Get<boolean>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/dataapi/pollOrganizationArchive/' + key,
			config,
			true
		);

		return response;
	}

	public GetOrgArchiveDownloadLink(key: string): string {
		return this.remoteActions.apiUrl + 'mapi/dataapi/downloadOrganizationArchive/' + key;
	}

	public async GenerateWellsArchive(): Promise<AxiosResponse<string>> {
		const config: AxiosRequestConfig = { responseType: 'text' };
		const response = await this.remoteActions.Post<string>(
			undefined,
			this.remoteActions.apiUrl + 'mapi/report/generateCSVExport',
			undefined,
			config,
			true
		);

		return response;
	}

	public GetWellsDownloadLink(key: string): string {
		return this.remoteActions.apiUrl + 'mapi/report/downloadCSVExport/' + key;
	}

	public async Notifications_GetAll(): Promise<AxiosResponse<DTNotification[]>> {
		const response = await this.remoteActions.Get<DTNotification[]>(
			() => new DTNotification(),
			this.remoteActions.apiUrl + 'mapi/nofication/notifications',
			undefined,
			true
		);

		return response;
	}

	public async Notifications_GetAllUnseen(): Promise<AxiosResponse<DTNotification[]>> {
		const response = await this.remoteActions.Get<DTNotification[]>(
			() => new DTNotification(),
			this.remoteActions.apiUrl + 'mapi/nofication/notifications/unseen',
			undefined,
			true
		);

		return response;
	}

	public async Notifications_MarkAsSeen(notId: string): Promise<AxiosResponse> {
		const response = await this.remoteActions.Post(
			undefined,
			this.remoteActions.apiUrl + 'mapi/noficationmarkAsSeen/' + notId,
			{},
			undefined,
			true
		);

		return response;
	}

	public async Notifications_Save(not: DTNotification): Promise<AxiosResponse<DTNotification>> {
		const response = await this.remoteActions.Put<DTNotification>(
			() => new DTNotification(),
			this.remoteActions.apiUrl + 'mapi/nofication',
			not,
			undefined,
			true
		);
		return response;
	}

	public async Notifications_Create(not: DTNotification): Promise<AxiosResponse<DTNotification>> {
		const response = await this.remoteActions.Post<DTNotification>(
			() => new DTNotification(),
			this.remoteActions.apiUrl + 'mapi/nofication',
			not,
			undefined,
			true
		);
		return response;
	}

	public async Notifications_Delete(notId: string): Promise<AxiosResponse> {
		const response = await this.remoteActions.Delete(undefined, this.remoteActions.apiUrl + 'mapi/nofication/' + notId, undefined, true);
		return response;
	}
}

class PollData {
	Installations: Well[] = [];
	Customers: Address[] = [];
	Companies: DTCompany[] = [];
	TimeStamp: string = '';
}

export enum UploadFileTypes {
	Installation = 'Installation',
	Report = 'Report',
	ServiceFile = 'ServiceFile',
	CompanyLogo = 'CompanyLogo'
}
