/* eslint-disable no-console */
import Address, { EAddressState } from '@/models/Address';
import Organisation from '@/models/Organisation';
import User from '@/models/User';
import Backbone from './Backbone';
import moment from 'moment';
import Well, { WellState } from '@/models/Well';
import { SignalDispatcher } from 'strongly-typed-events';
import DTCompany from '@/models/DTCompany';

export default class State {
	public User: User = new User();
	public Users: User[] = [];
	public Organisation: Organisation = new Organisation();
	public Addresses: Address[] = [];
	public Companies: DTCompany[] = [];

	private _onChanged = new SignalDispatcher();

	public get OnChanged() {
		return this._onChanged.asEvent();
	}

	public GetUserById(userId: string): User | undefined {
		return this.Users.find((u: User) => u.Id == userId);
	}

	public async InitState(): Promise<boolean> {
		if (!Backbone.RemoteActions.authToken || Backbone.RemoteActions.authToken.length == 0) {
			return false;
		}

		const date = moment();
		console.log('Innitting state started.');
		try {
			const r = await Backbone.RemoteActions.User.Me();
			if (r && r.status < 300) {
				// success
				if (!r.data.IsAdministrator) {
					console.log('Bruger har ikke de fornødne rettigheder til at logge på');
					return false;
				}
				this.User = r.data;

				console.log('Userdata loaded!');
			} else {
				return false;
			}

			const orgData = await Backbone.RemoteActions.User.MyOrganisation();
			if (orgData && orgData.status < 300) {
				// success
				this.Organisation = orgData.data;

				console.log('OrgData loaded!');
			} else {
				return false;
			}

			const rUsers = await Backbone.RemoteActions.Onboarding.Users();
			if (rUsers.status < 300) {
				// success
				this.Users = rUsers.data;

				console.log('Users loaded!');
			}

			await Backbone.RemotePoller.Start();

			console.log('Init finished in ' + moment().diff(date) / 1000 + ' seconds');

			// Check notifications
			const newNotifs = await Backbone.RemoteActions.Other.Notifications_GetAllUnseen();
			if (newNotifs.status < 300) {
				const list = newNotifs.data.sort((a, b) => a.CreatedDate.valueOf() - b.CreatedDate.valueOf());
				for (let i = 0; i < list.length; i++) {
					const notif = list[i];
					if (notif.CreatedDate.isBefore(moment().subtract(1, 'year'))) {
						continue;
					}
					const result = await Backbone.UI.OpenConfirmDialog(notif.Name, notif.Content.replaceAll('\n', '<br />'), false, {
						width: 500,
						primaryBtnText: 'Luk'
					});
					if (result) {
						Backbone.RemoteActions.Other.Notifications_MarkAsSeen(notif.Id);
					}
				}
			}

			return true;
		} catch (e) {
			console.log(e);
			return false;
		}

		return false;
	}

	public UpdateFromPollData(addresses: Address[], wells: Well[], companies: DTCompany[]) {
		if (addresses.length == 0 && wells.length == 0 && companies.length == 0) {
			return;
		}

		addresses.forEach((address) => {
			const existingAddress = this.Addresses.find((a: Address) => {
				return a.Id == address.Id;
			});

			if (existingAddress) {
				if (address.State != EAddressState.Active) {
					const index = this.Addresses.indexOf(existingAddress);
					this.Addresses.splice(index, 1);
				}

				Object.assign(existingAddress, address);
				existingAddress.Deserialize();
			} else if (address.State == EAddressState.Active) {
				address = Object.assign(new Address(), address);
				address.Deserialize();
				this.Addresses.push(address);
			}
		});

		wells.forEach((well) => {
			const existingAddress = this.Addresses.find((a: Address) => {
				return a.Id == well.CustomerId;
			});

			if (existingAddress) {
				const existingWell = existingAddress.Wells.find((w: Well) => {
					return w.Id == well.Id;
				});

				if (existingWell) {
					if (well.State != WellState.Active) {
						// the well is inactive and should be removed from list
						//get index of well
						const index = existingAddress.Wells.indexOf(existingWell);
						existingAddress.Wells.splice(index, 1);
					} else {
						Object.assign(existingWell, well);
						existingWell.Deserialize();
					}
				} else if (well.State == WellState.Active) {
					// Only if the well is active - otherwise skip
					well = Object.assign(new Well(), well);
					well.Deserialize();
					existingAddress.Wells.push(well);
				}

				existingAddress.Deserialize();
			} else if (well.State == WellState.Active) {
				console.error('Well found for unknown address\n' + JSON.stringify(well, undefined, '\t'));
			}
		});

		// get companies
		companies.forEach((company) => {
			const existing = this.Companies.find((c: DTCompany) => {
				return c.Id == company.Id;
			});

			if (!existing) {
				if (company.State == EAddressState.Active) {
					company = Object.assign(new DTCompany(), company);
					this.Companies.push(company);
					company.Deserialize();
					//newCompany.LastUpdate = result.Content.TimeStamp;
				}
			} else {
				if (company.State == EAddressState.Active) {
					Object.assign(existing, company);
					existing.Deserialize();
				} else {
					const index = this.Companies.indexOf(existing);
					this.Companies.splice(index, 1);
					existing.Deserialize(); // make sure to invoke OnChanged event
				}
				//existing.LastUpdate = result.Content.TimeStamp;
			}
		});

		console.log('Poll data updated:\n\tAddresses: ' + addresses.length + '\n\tWells: ' + wells.length + '\nCompanies: ' + companies.length);
		if (addresses.length + wells.length + companies.length > 0) {
			this._onChanged.dispatch();
		}
	}

	/**
	 * Clear the state and auth token - basically logs out
	 */
	public Clear() {
		Backbone.RemoteActions.authToken = '';
		this.User = new User();
		this.Users.length = 0;
		this.Addresses.length = 0;
		this.Companies.length = 0;
		this._onChanged.dispatch();
		Backbone.RemotePoller.dataTimeStamp = moment(0);
		Backbone.RemotePoller.Stop();
	}
}
