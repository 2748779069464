import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import RemoteActions from './RemoteActions';
import User from '@/models/User';
import Organisation from '@/models/Organisation';

export default class RA_Onboarding {
	private remoteActions: RemoteActions;

	constructor(remoteActions: RemoteActions) {
		this.remoteActions = remoteActions;
	}

	public async Users(): Promise<AxiosResponse<User[]>> {
		const response = await this.remoteActions.Get<User[]>(() => new User(), this.remoteActions.apiUrl + 'onb/users', undefined, true);
		return response;
	}

	public async CreateOrganisation(
		OrganizationName: string,
		UserEmail: string,
		UserName: string,
		Phonenumber: string,
		CVRNumber: string,
		Address: string,
		newsletter: boolean
	): Promise<AxiosResponse<any>> {
		const config: AxiosRequestConfig = {
			responseType: 'json'
			// headers: {
			// 	'Authorization': "Bearer " + this.remoteActions.authToken
			// }
		};

		const body = {
			OrganizationName,
			UserEmail,
			UserName,
			MailSupport: newsletter,
			CVRNumber,
			Address,
			Phonenumber
		};

		try {
			const response = await axios.post<any>(this.remoteActions.apiUrl + 'onb/organization/createorganization', body, config);
			return response;
		} catch (e: any) {
			return e.response;
		}
	}
	/**
	 * View Organization
	 */
	public async ViewOrganization(token: string): Promise<AxiosResponse<{ Organization: Organisation; User: User; Token: string }>> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				//'Authorization': "Bearer " + this.remoteActions.authToken
			}
		};

		try {
			const response = await this.remoteActions.axios.get<{ Organization: Organisation; User: User; Token: string }>(
				this.remoteActions.apiUrl + 'onb/organization/' + token,
				config
			);
			return response;
		} catch (e: any) {
			return e.response;
		}
	}

	public async ConfirmOrganization(confirmToken: string, approval: boolean): Promise<AxiosResponse<any>> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				//'Authorization': "Bearer " + this.remoteActions.authToken
			}
		};

		const body = {
			confirmToken,
			approval
		};

		try {
			const response = await this.remoteActions.axios.post<any>(this.remoteActions.apiUrl + 'onb/confirmorganization', body, config);
			return response;
		} catch (e: any) {
			return e.response;
		}
	}

	public async SendInvite(email: string, userName: string, role: string): Promise<AxiosResponse<any>> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				Authorization: 'Bearer ' + this.remoteActions.authToken
			}
		};

		const body = {
			email,
			userName,
			UserRole: role
		};
		try {
			const response = await axios.post<any>(this.remoteActions.apiUrl + 'onb/user/invite', body, config);
			return response;
		} catch (e: any) {
			return e.response;
		}
	}

	public async InviteData(token: string): Promise<AxiosResponse<any>> {
		try {
			const response = await this.remoteActions.Get<any>(
				undefined,
				this.remoteActions.apiUrl + 'onb/user/invite/' + token,
				undefined,
				false
			);
			return response;
		} catch (e: any) {
			return e.response;
		}
	}

	public async ConfirmInvite(InviteToken: string, Password: string): Promise<AxiosResponse<any>> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				//'Authorization': "Bearer " + this.remoteActions.authToken
			}
		};

		const body = {
			InviteToken,
			Password
		};

		try {
			const response = await this.remoteActions.axios.post<any>(this.remoteActions.apiUrl + 'onb/user/confirminvite', body, config);
			return response;
		} catch (e: any) {
			return e.response;
		}
	}

	public async RequestResetPassword(emailAddress: string): Promise<AxiosResponse<any>> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				//'Authorization': "Bearer " + this.remoteActions.authToken
			}
		};

		const data: FormData = new FormData();
		data.append('emailAddress', emailAddress);

		try {
			const response = await this.remoteActions.axios.post<any>(this.remoteActions.apiUrl + 'onb/resetpassword', data, config);
			return response;
		} catch (e: any) {
			return e.response;
		}
	}

	public async ResetPassword(PasswordResetToken: string, Password: string): Promise<AxiosResponse<any>> {
		const config: AxiosRequestConfig = {
			responseType: 'json',
			headers: {
				//'Authorization': "Bearer " + this.remoteActions.authToken
			}
		};

		const body = {
			PasswordResetToken,
			Password
		};

		try {
			const response = await this.remoteActions.axios.post<any>(this.remoteActions.apiUrl + 'onb/user/confirmpasswordreset', body, config);
			return response;
		} catch (e: any) {
			return e.response;
		}
	}
}
