/* eslint-disable no-console */
import { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import RA_User from './RA_User';
import RA_ServiceVisit from './RA_ServiceVisit';
import RA_Customer from './RA_Customer';
import RA_Onboarding from './RA_Onboarding';
import RA_Other from './RA_Other';
import RA_ScheduledVisit from './RA_ScheduledVisit';
import Axios from 'axios';
import router from '@/router';
import Serializer from '@/Misc/Serializer';
import Backbone from './Backbone';
import RA_Banner from './RA_Banner';

export default class RemoteActions {
	public apiUrl: string = process.env.VUE_APP_SERVER_URL!;

	private _authToken = '';
	public get authToken(): string {
		return this._authToken;
	}
	public set authToken(value: string) {
		this._authToken = value;
		localStorage.setItem('authToken', value);
	}

	public User: RA_User;
	public ServiceVisit: RA_ServiceVisit;
	public Address: RA_Customer;
	public Onboarding: RA_Onboarding;
	public Other: RA_Other;
	public ScheduledVisit: RA_ScheduledVisit;
	public Banner: RA_Banner;

	public axios: AxiosInstance;

	constructor() {
		this.authToken = localStorage.getItem('authToken') || '';
		this.axios = Axios.create({});

		this.axios.interceptors.response.use(undefined, function (err: any) {
			return new Promise(function (resolve, reject) {
				if (err.response.status === 401) {
					console.log('Unauthorised');

					if (router != null && document.location.pathname != '/') {
						console.log('Redirecting to login');
						Backbone.RemotePoller.Stop();
						//document.location.href="/";
					}
				}
				return reject(err);
			});
		});

		this.User = new RA_User(this);
		this.ServiceVisit = new RA_ServiceVisit(this);
		this.Address = new RA_Customer(this);
		this.Onboarding = new RA_Onboarding(this);
		this.Other = new RA_Other(this);
		this.ScheduledVisit = new RA_ScheduledVisit(this);
		this.Banner = new RA_Banner(this);
	}

	public async Get<T = any>(
		constructor: (() => any) | undefined,
		url: string,
		config?: AxiosRequestConfig | undefined,
		authCall?: boolean
	): Promise<AxiosResponse<T>> {
		try {
			config = this.PrepareConfig(config, authCall);
			const response = await this.axios.get<T>(url, config);
			if (response.status < 300) {
				response.data = Serializer.Deserialize(response.data, constructor);
			}

			return response;
		} catch (e: any) {
			console.error(e.response);
			return e.response;
		}
	}

	public async GetNonGeneric(url: string, config?: AxiosRequestConfig | undefined, authCall?: boolean): Promise<AxiosResponse> {
		try {
			config = this.PrepareConfig(config, authCall);
			const response = await this.axios.get(url, config);
			console.log(response);

			return response;
		} catch (e: any) {
			console.error(e.response);
			return e.response;
		}
	}

	public async Post<T = any>(
		constructor: (() => any) | undefined,
		url: string,
		data?: any,
		config?: AxiosRequestConfig | undefined,
		authCall?: boolean
	): Promise<AxiosResponse<T>> {
		config = this.PrepareConfig(config, authCall);

		try {
			const response = await this.axios.post<T>(url, data, config);

			if (response.status < 300) {
				response.data = Serializer.Deserialize(response.data, constructor);
			}

			return response;
		} catch (error: any) {
			console.error(error.response);
			return error.response;
		}
	}

	public async Patch<T = any>(
		constructor: (() => any) | undefined,
		url: string,
		data?: any,
		config?: AxiosRequestConfig | undefined,
		authCall?: boolean
	): Promise<AxiosResponse<T>> {
		config = this.PrepareConfig(config, authCall);
		try {
			const response = await this.axios.patch<T>(url, data, config);
			if (response.status < 300) {
				response.data = Serializer.Deserialize(response.data, constructor);
			}
			return response;
		} catch (error: any) {
			console.error(error.response);
			return error.response;
		}
	}

	public async Put<T = any>(
		constructor: (() => any) | undefined,
		url: string,
		data?: any,
		config?: AxiosRequestConfig | undefined,
		authCall?: boolean
	): Promise<AxiosResponse<T>> {
		config = this.PrepareConfig(config, authCall);

		try {
			const response = await this.axios.put(url, data, config);

			if (response.status < 300) {
				response.data = Serializer.Deserialize(response.data, constructor);
			}
			return response;
		} catch (error: any) {
			console.error(error.response);
			return error.response;
		}
	}

	public async Delete<T = any>(
		constructor: (() => any) | undefined,
		url: string,
		config?: AxiosRequestConfig | undefined,
		authCall?: boolean
	): Promise<AxiosResponse<T>> {
		config = this.PrepareConfig(config, authCall);
		const response = await this.axios.delete<T>(url, config);

		try {
			if (response.status < 300) {
				response.data = Serializer.Deserialize(response.data, constructor);
			}

			return response;
		} catch (error: any) {
			console.error(error.response);
			return error.response;
		}
	}

	public PrepareConfig(config: AxiosRequestConfig | undefined, authCall?: boolean): AxiosRequestConfig {
		if (config == undefined) {
			config = { responseType: 'json' };
		}

		if (authCall == undefined || authCall == true) {
			if (config.headers == undefined) {
				config.headers = {};
			}
			config.headers['Authorization'] = 'Bearer ' + this.authToken;
		}
		return config;
	}

	public GetFilePath(downloadKey: string): string {
		if (downloadKey == '') {
			return '/gfx/photo_broken.png';
		}
		return this.apiUrl + 'mapi/file/' + downloadKey;
	}
}
