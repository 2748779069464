import Serializer from '@/Misc/Serializer';
import moment, { Moment } from 'moment';
import Well from './Well';
import Address from './Address';

export default class Report implements IReport {
	Id: string = '';
	OrganizationId: string = '';
	CustomerId: string = '';
	DownloadKey: string = '';
	CreatedDate: Moment = moment();
	ReportType: ReportType = ReportType.ServiceReport;
	Context: Context = new Context();
	VisitDate?: Moment | undefined;

	public get visitOrCreatedDate(): Moment {
		if (this.ReportType == ReportType.ServiceReport && this.VisitDate && this.VisitDate.isAfter(0)) {
			return this.VisitDate;
		}

		return this.CreatedDate;
	}

	public Deserialize() {
		Serializer.StringToMoment(['VisitDate', 'CreatedDate', 'ModifiedDate'], this);
		if (this.Context) {
			this.Context = Serializer.Deserialize(this.Context, () => new Context());
		}
	}

	public GetInstallationName() {
		return this.Context.Installation!.Name;
	}

	public GetInstallationTypeName() {
		return this.ReportType == ReportType.ServiceReport ? 'Servicerapport' : 'Installationsrapport';
	}

	public GetInstallationTypeIcon() {
		return this.ReportType == ReportType.ServiceReport ? '/gfx/report_circle.png' : '/gfx/installation_report_circle.png';
	}
}

export interface IReport {
	Id: string;
	OrganizationId: string;
	CustomerId: string;
	VisitDate?: Moment;
	DownloadKey: string;
	CreatedDate: Moment;
	//ModifiedDate: Moment; //Not used for installations reports
	ReportType: ReportType;
	Context: Context;
}

export enum ReportType {
	ServiceReport = 'ServiceReport',
	InstallationReport = 'ServiceReport'
}

export class Context {
	UserName?: string;
	UserId?: string;
	OrganizationName?: string;
	OrganizationId?: string;
	Customer?: Address;
	Installation?: Well;
}
