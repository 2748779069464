





































































































/* eslint-disable prettier/prettier */

import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import router from '@/router';

@Component({})
export default class LoginForm extends Vue {
	data(): any {
		return {
			valid: true,
			email: '',
			emailRules: [
				(v: any) => !!v || 'E-mail skal udfyldes.',
				(v: any) => this.EmailValid(v) || 'E-mailadressen er ikke formateret korrekt!'
			],
			password: '',
			passwordRules: [(v: any) => !!v || 'Password skal udfyldes.'],
			alert: false,
			alertText: '',
			dialog: false,
			loading: false
		};
	}

	EmailValid(email: string): boolean {
		return /.+@.+\..+/.test(email);
	}

	async LoginClicked() {
		if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			return;
		}

		this.$data.loading = true;

		let response = await Backbone.RemoteActions.User.Login(this.$data.email, this.$data.password);
		if (response && response.status < 300) {
			let r = await Backbone.State.InitState();
			if (r) {
				if (!Backbone.State.Organisation.PrivacyPolicyAgreed) {
					router.push('/confirmprivacypolicy');
				} else {
					router.push('/addresslist');
				}
				return;
			}
		}
		this.$data.loading = false;

		if (response && response.status == 426) {
			this.$data.alertText = 'Logon mislykkedes - klient version ikke godkendt af server!';
		} else {
			this.$data.alertText = 'Logon mislykkedes!';
		}
		this.$data.alert = true;
	}

	async ResetClicked() {
		if (!this.EmailValid(this.$data.email)) {
			this.$data.alert = true;
			this.$data.alertText = 'Den indtastede email-adresse er ikke formateret korrekt!';
			return;
		}

		this.$data.loading = true;
		let response = await Backbone.RemoteActions.Onboarding.RequestResetPassword(this.$data.email);
		this.$data.loading = false;

		if (response && response.status < 300) {
			this.$data.dialog = true;
			return;
		}

		this.$data.alert = true;
		this.$data.alertText = '\'Opret nyt password\' mislykkedes! Tjek om e-mail-adressen er korrekt.';
	}
}
