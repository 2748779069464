export default {
	close: 'Luk',
	dataIterator: {
		noResultsText: 'No matching records found',
		loadingText: 'Loading items...'
	},
	dataTable: {
		itemsPerPageText: 'Rækker per side:',
		ariaLabel: {
			sortDescending: 'Sorted descending.',
			sortAscending: 'Sorted ascending.',
			sortNone: 'Not sorted.',
			activateNone: 'Activate to remove sorting.',
			activateDescending: 'Activate to sort descending.',
			activateAscending: 'Activate to sort ascending.'
		},
		sortBy: 'Sort by'
	},
	dataFooter: {
		itemsPerPageText: 'Items per page:',
		itemsPerPageAll: 'All',
		nextPage: 'Next page',
		prevPage: 'Previous page',
		firstPage: 'First page',
		lastPage: 'Sidste',
		pageText: '{0}-{1} af {2}'
	},
	datePicker: {
		itemsSelected: '{0} valgte'
	},
	noDataText: 'Ingen data tilgængelig',
	carousel: {
		prev: 'Previous visual',
		next: 'Next visual',
		ariaLabel: {
			delimiter: 'Carousel slide {0} of {1}'
		}
	},
	calendar: {
		moreEvents: '{0} mere'
	},
	fileInput: {
		counter: '{0} filer',
		counterSize: '{0} filer ({1} i alt)'
	},
	timePicker: {
		am: 'AM',
		pm: 'PM'
	}
};
