import DTLocation from '@/models/DTLocation';
import moment from 'moment';

export default class Serializer {
	public static StringToDate(members: string | string[], source: any) {
		if (typeof members === 'string') {
			members = [members];
		}

		members.forEach((element) => {
			if (source[element]) {
				source[element] = new Date(source[element]);
			} else {
				source[element] = undefined;
			}
		});
	}

	public static StringToMoment(members: string | string[], source: any) {
		if (typeof members === 'string') {
			members = [members];
		}

		members.forEach((element) => {
			if (source[element]) {
				source[element] = moment(source[element]);
			} else {
				source[element] = undefined;
			}
		});
	}

	public static StringToMomentWithFallback(member: string, source: any, fallbackValue: moment.Moment) {
		source[member + '__'] = source[member];
		if (source[member]) {
			source[member] = moment(source[member]);
		} else {
			source[member] = fallbackValue;
		}
	}

	public static StringToLocation(members: string | string[], source: any) {
		if (typeof members === 'string') {
			members = [members];
		}

		members.forEach((element) => {
			source[element] = new DTLocation(source[element]);
		});
	}

	public static Deserialize(data: any, constructor: (() => any) | undefined): any {
		if (Array.isArray(data)) {
			for (let i = 0; i < data.length; i++) {
				const s = data[i];
				if (constructor != undefined) {
					const r = constructor();
					Object.assign(r, s);
					if (r['Deserialize'] != undefined) {
						r['Deserialize']();
					}
					data[i] = r;
				} else {
					if (s['Deserialize'] != undefined) {
						s['Deserialize']();
					}
				}
			}
		} else {
			if (constructor != undefined) {
				const r = constructor();
				data = Object.assign(r, data);
				if (r['Deserialize'] != undefined) {
					r['Deserialize']();
				}
			} else {
				if (data['Deserialize'] != undefined) {
					data['Deserialize']();
				}
			}
		}

		return data;
	}

	public static get MomentMin(): moment.Moment {
		return moment.utc(-2208988800000);
	}
}

export enum MappingType {
	Date
}
