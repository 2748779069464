import moment from 'moment';
import Serializer from '@/Misc/Serializer';

export default class DTBannerInfo {
	public Id: string | null = null;
	public OrganizationId: string | null = '';
	public Name: string | null = '';
	public FileName: string | null = '';
	public Url: string | null = '';
	public Note: string | null = '';
	public ContentType: string | null = '';
	public State: EBannerState = EBannerState.Active;
	public ObjectStorageKey: string | null = '';
	public DownloadKey: string | null = '';
	public CreatedDate: moment.Moment = moment();
	public ModifiedDate: moment.Moment = moment();
	public CreatedBy: string | null = '';
	public CreatedByUserId: string | null = '';
	public Size: number = 0;
	public Width: number = 0;
	public Height: number = 0;

	public Deserialize() {
		Serializer.StringToMomentWithFallback('CreatedDate', this, moment());
		Serializer.StringToMomentWithFallback('ModifiedDate', this, moment());
	}
}

export enum EBannerState {
	Active = 'Active',
	Inactive = 'Inactive',
	Deleted = 'Deleted'
}
